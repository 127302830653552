<template>
  <div class="mt-2 mb-1">
    <div class="mb-50 d-flex-between">
      <div class="fw-700 font-medium-3 text-danger ml-1">
        {{ $t('reservation[\'CHOOSE INSURANCE PACKAGE\']') }}
      </div>
    </div>

    <div class="border-left-secondary border-right-secondary overflow-auto d-xl-flex justify-content-center border-bottom-black">
      <table
        cellpadding="15px"
        cellspacing="10px"
      >
        <tr class="border-top-black">
          <th>
            {{ $t('reservation[\'INSURANCE BENEFITS\']') }}
            <p>
              {{ $t('reservation[\'(Unit: USD)\']') }}
            </p>
            <div>
              <b-button
                variant="outline-warning"
                class="py-50 px-75 rounded-lg"
                @click="() => showDiscount = !showDiscount"
              >
                <feather-icon
                  :icon="showDiscount ? 'EyeOffIcon' : 'EyeIcon'"
                  class="mr-25"
                  size="16"
                />
                {{ showDiscount ? $t('reservation[\'Hide discount\']') : $t('reservation[\'Show discount\']') }}
              </b-button>
            </div>
          </th>
          <td
            v-for="(item, index) of data"
            :key="index"
            class="text-center"
          >
            <div class="d-flex-between flex-column">
              <b-badge
                variant="danger"
                class="w-100 px-3 py-50"
                pill
              >
                {{ item.name }}
              </b-badge>

              <template v-if="showDiscount">
                <div class="text-muted fw-600 text-decoration-line">
                  {{ formatCurrency(item.price) }}
                </div>
                <div :class="`${showDiscount ? `mb-0` : `mb-50` } text-center fw-800 font-medium-${isMobileView ? '2' : '3' }`">
                  {{ formatCurrency(item.totalPrice) }} VND
                </div>
                <div class="mb-50 fw-600 text-success">
                  {{ $t('reservation[\'benefit\']') }}: <span class="text-nowrap"> {{ formatCurrency(item.priceCommission) }} VND</span>
                </div>
              </template>
              <template v-else>
                <div :class="`my-50 text-center fw-800 font-medium-${isMobileView ? '2' : '3' }`">
                  {{ formatCurrency(item.price) }} VND
                </div>
              </template>

              <div style="min-height:44px">
                <b-button
                  v-if="dataInsuranceDraft && dataInsuranceDraft.planId && (dataInsuranceDraft.planId === item.id)"
                  variant="outline-warning"
                  class="d-flex-center"
                  disabled
                  @click="() => $emit('handleSelect', item)"
                >
                  <span>
                    {{ $t('reservation[\'Old package\']') }}
                  </span>
                </b-button>

                <b-button
                  v-else
                  :variant="selected && (selected.id === item.id) ? 'success' : 'outline-secondary'"
                  class="d-flex-center"
                  @click="() => $emit('handleSelect', item)"
                >
                  <feather-icon
                    :icon="selected && (selected.id === item.id) ? 'CheckCircleIcon' : 'CircleIcon'"
                    size="20"
                    class="mr-50 fw-700"
                  />
                  <span>
                    {{ selected && (selected.id === item.id) ? $t('reservation[\'Selected\']') : $t('reservation[\'Select package\']') }}
                  </span>
                </b-button>
              </div>
            </div>
          </td>
        </tr>

        <tr class="">
          <th>
            <b-badge
              variant="info"
              class="w-100 py-50"
              pill
            >
              {{ $t('reservation[\'Personal accident\']') }}
            </b-badge>
          </th>
          <td
            v-for="(item, index) of dataListInsurances"
            :key="index"
            class="text-center fw-700"
          >
            {{ formatCurrency(benefits[item.id]) }}
          </td>
        </tr>
        <tr class="">
          <th>
            <b-badge
              variant="info"
              class="w-100 py-50"
              pill
            >
              {{ $t('reservation[\'Medical costs\']') }}
            </b-badge>
          </th>
          <td
            v-for="(item, index) of dataListInsurances"
            :key="index"
            class="text-center fw-700"
          >
            {{ formatCurrency(benefits[item.id]) }}
          </td>
        </tr>
        <tr class="">
          <th>
            <b-badge
              variant="info"
              class="w-100 py-50"
              pill
            >
              {{ $t('reservation[\'Medical aid\']') }}
            </b-badge>
          </th>
          <!-- <td
            v-for="(item, index) of dataListInsurances"
            :key="index"
            class="text-center fw-700"
          >
            {{ formatCurrency(benefits[item.id]) }}
          </td> -->
          <td
            :colspan="dataListInsurances.length"
            class="text-center text-info fw-600"
          >
            <BLink
              :href="docLink"
              target="_blank"
              class="cursor-pointer d-flex-center"
            >
              <span>{{ $t('reservation[\'See details in the benefits table\']') }}</span>
              <feather-icon
                :icon="'ExternalLinkIcon'"
                class="ml-50"
              />
            </BLink>
          </td>
        </tr>
        <tr class="">
          <th>
            <b-badge
              variant="info"
              class="w-100 py-50"
              pill
            >
              {{ $t('reservation[\'Travel support\']') }}
            </b-badge>
          </th>
          <!-- <td
            v-for="(item, index) of dataListInsurances"
            :key="index"
            class="text-center fw-700"
          >
            {{ formatCurrency(benefits[item.id]) }}
          </td> -->
          <td
            :colspan="dataListInsurances.length"
            class="text-center text-info fw-600"
          >
            <BLink
              :href="docLink"
              target="_blank"
              class="cursor-pointer d-flex-center"
            >
              <span>{{ $t('reservation[\'See details in the benefits table\']') }}</span>
              <feather-icon
                :icon="'ExternalLinkIcon'"
                class="ml-50"
              />
            </BLink>
          </td>
        </tr>
        <tr class="">
          <th>
            <b-badge
              variant="info"
              class="w-100 py-50"
              pill
            >
              {{ $t('reservation[\'IPA Relief Services\']') }}
            </b-badge>
          </th>
          <td
            v-for="(item, index) of dataListInsurances"
            :key="index"
            class="text-center fw-700"
          >
            {{ $t('reservation[\'Include\']') }}
          </td>
        </tr>
        <tr>
          <th>
            <b-badge
              variant="info"
              class="w-100 py-50"
              pill
            >
              {{ $t('reservation[\'Costs related to Covid-19\']') }}
            </b-badge>
          </th>
        </tr>

        <tr>
          <th class="text-right">
            <b-badge
              variant="warning"
              class="py-50 px-75"
              pill
            >
              {{ $t('reservation[\'Up to 70 years old\']') }}
            </b-badge>
          </th>
          <td :colspan="dataListInsurances.length">
            <div class="d-flex-between fw-700">
              <div class="flex-1 mr-50 text-center">
                <div class="flex-1 text-center mb-50">
                  {{ $t('reservation[\'Apply package\']') }}: <span class="text-info">Cơ bản 1, cơ bản 2</span>
                </div>
                {{ $t('reservation[\'Subject to limits and health insurance benefits specified in the contract/Certificate of insurance\']') }}
              </div>
              <div class="flex-1 ml-50 text-center">
                <div class="flex-1 text-center mb-50">
                  {{ $t('reservation[\'Apply package\']') }}: <span class="text-danger">Phổ thông, cao cấp, thượng hạng</span>
                </div>
                {{ $t('reservation[\'Maximum $50,000/person/Insured period, whichever is lower\']') }}
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <th class="text-right">
            <b-badge
              variant="warning"
              class="py-50 px-75"
              pill
            >
              {{ $t('reservation[\'Over 70 years old\']') }}
            </b-badge>
          </th>
          <td :colspan="dataListInsurances.length">
            <div class="d-flex-between fw-700">
              <div class="flex-1 mr-50 text-center">
                {{ $t('reservation[\'$5,000/person/insurance period, whichever is lower\']') }}
              </div>
              <div class="flex-1 ml-50 text-center">
                {{ $t('reservation[\'Maximum $5,000/person/term of insurance, whichever is lower\']') }}
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BBadge,
  BLink,
} from 'bootstrap-vue'
import isEmpty from 'lodash/isEmpty'
import {
  computed,
  ref,
  toRefs,
} from '@vue/composition-api'

import {
  formatCurrency,
} from '@/@core/utils/filter'

export default {
  components: {
    BButton,
    BBadge,
    BLink,
  },
  props: {
    dataListInsurances: {
      type: [Array, null],
      default: () => null,
    },
    selected: {
      type: [Object, null],
      default: () => null,
    },
    dataInsuranceDraft: {
      type: [Object, null],
      default: () => null,
    },
  },
  setup(props) {
    const { dataListInsurances } = toRefs(props)
    const showDiscount = ref(false)

    const docLink = ref('https://drive.google.com/file/d/1Pyj31eETjCaQiPPDayfWdECHCrmYotyw/view?usp=sharing')

    const benefits = {
      1: 5000,
      2: 10000,
      3: 50000,
      4: 100000,
      5: 150000,
    }

    const data = computed(() => {
      const sortedList = dataListInsurances.value?.sort((a, b) => (a?.price || 1) - (b?.price || 0))
      return sortedList || []
    })
    return {
      data,
      benefits,
      showDiscount,
      isEmpty,
      docLink,
      formatCurrency,
    }
  },
}
</script>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-2 mb-1"
  }, [_c('div', {
    staticClass: "mb-50 d-flex-between"
  }, [_c('div', {
    staticClass: "fw-700 font-medium-3 text-danger ml-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'CHOOSE INSURANCE PACKAGE\']')) + " ")])]), _c('div', {
    staticClass: "border-left-secondary border-right-secondary overflow-auto d-xl-flex justify-content-center border-bottom-black"
  }, [_c('table', {
    attrs: {
      "cellpadding": "15px",
      "cellspacing": "10px"
    }
  }, [_c('tr', {
    staticClass: "border-top-black"
  }, [_c('th', [_vm._v(" " + _vm._s(_vm.$t('reservation[\'INSURANCE BENEFITS\']')) + " "), _c('p', [_vm._v(" " + _vm._s(_vm.$t('reservation[\'(Unit: USD)\']')) + " ")]), _c('div', [_c('b-button', {
    staticClass: "py-50 px-75 rounded-lg",
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": function click() {
        return _vm.showDiscount = !_vm.showDiscount;
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": _vm.showDiscount ? 'EyeOffIcon' : 'EyeIcon',
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.showDiscount ? _vm.$t('reservation[\'Hide discount\']') : _vm.$t('reservation[\'Show discount\']')) + " ")], 1)], 1)]), _vm._l(_vm.data, function (item, index) {
    return _c('td', {
      key: index,
      staticClass: "text-center"
    }, [_c('div', {
      staticClass: "d-flex-between flex-column"
    }, [_c('b-badge', {
      staticClass: "w-100 px-3 py-50",
      attrs: {
        "variant": "danger",
        "pill": ""
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _vm.showDiscount ? [_c('div', {
      staticClass: "text-muted fw-600 text-decoration-line"
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.price)) + " ")]), _c('div', {
      class: "".concat(_vm.showDiscount ? "mb-0" : "mb-50", " text-center fw-800 font-medium-").concat(_vm.isMobileView ? '2' : '3')
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalPrice)) + " VND ")]), _c('div', {
      staticClass: "mb-50 fw-600 text-success"
    }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'benefit\']')) + ": "), _c('span', {
      staticClass: "text-nowrap"
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.priceCommission)) + " VND")])])] : [_c('div', {
      class: "my-50 text-center fw-800 font-medium-".concat(_vm.isMobileView ? '2' : '3')
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.price)) + " VND ")])], _c('div', {
      staticStyle: {
        "min-height": "44px"
      }
    }, [_vm.dataInsuranceDraft && _vm.dataInsuranceDraft.planId && _vm.dataInsuranceDraft.planId === item.id ? _c('b-button', {
      staticClass: "d-flex-center",
      attrs: {
        "variant": "outline-warning",
        "disabled": ""
      },
      on: {
        "click": function click() {
          return _vm.$emit('handleSelect', item);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Old package\']')) + " ")])]) : _c('b-button', {
      staticClass: "d-flex-center",
      attrs: {
        "variant": _vm.selected && _vm.selected.id === item.id ? 'success' : 'outline-secondary'
      },
      on: {
        "click": function click() {
          return _vm.$emit('handleSelect', item);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "mr-50 fw-700",
      attrs: {
        "icon": _vm.selected && _vm.selected.id === item.id ? 'CheckCircleIcon' : 'CircleIcon',
        "size": "20"
      }
    }), _c('span', [_vm._v(" " + _vm._s(_vm.selected && _vm.selected.id === item.id ? _vm.$t('reservation[\'Selected\']') : _vm.$t('reservation[\'Select package\']')) + " ")])], 1)], 1)], 2)]);
  })], 2), _c('tr', {}, [_c('th', [_c('b-badge', {
    staticClass: "w-100 py-50",
    attrs: {
      "variant": "info",
      "pill": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Personal accident\']')) + " ")])], 1), _vm._l(_vm.dataListInsurances, function (item, index) {
    return _c('td', {
      key: index,
      staticClass: "text-center fw-700"
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.benefits[item.id])) + " ")]);
  })], 2), _c('tr', {}, [_c('th', [_c('b-badge', {
    staticClass: "w-100 py-50",
    attrs: {
      "variant": "info",
      "pill": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Medical costs\']')) + " ")])], 1), _vm._l(_vm.dataListInsurances, function (item, index) {
    return _c('td', {
      key: index,
      staticClass: "text-center fw-700"
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.benefits[item.id])) + " ")]);
  })], 2), _c('tr', {}, [_c('th', [_c('b-badge', {
    staticClass: "w-100 py-50",
    attrs: {
      "variant": "info",
      "pill": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Medical aid\']')) + " ")])], 1), _c('td', {
    staticClass: "text-center text-info fw-600",
    attrs: {
      "colspan": _vm.dataListInsurances.length
    }
  }, [_c('BLink', {
    staticClass: "cursor-pointer d-flex-center",
    attrs: {
      "href": _vm.docLink,
      "target": "_blank"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('reservation[\'See details in the benefits table\']')))]), _c('feather-icon', {
    staticClass: "ml-50",
    attrs: {
      "icon": 'ExternalLinkIcon'
    }
  })], 1)], 1)]), _c('tr', {}, [_c('th', [_c('b-badge', {
    staticClass: "w-100 py-50",
    attrs: {
      "variant": "info",
      "pill": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Travel support\']')) + " ")])], 1), _c('td', {
    staticClass: "text-center text-info fw-600",
    attrs: {
      "colspan": _vm.dataListInsurances.length
    }
  }, [_c('BLink', {
    staticClass: "cursor-pointer d-flex-center",
    attrs: {
      "href": _vm.docLink,
      "target": "_blank"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('reservation[\'See details in the benefits table\']')))]), _c('feather-icon', {
    staticClass: "ml-50",
    attrs: {
      "icon": 'ExternalLinkIcon'
    }
  })], 1)], 1)]), _c('tr', {}, [_c('th', [_c('b-badge', {
    staticClass: "w-100 py-50",
    attrs: {
      "variant": "info",
      "pill": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'IPA Relief Services\']')) + " ")])], 1), _vm._l(_vm.dataListInsurances, function (item, index) {
    return _c('td', {
      key: index,
      staticClass: "text-center fw-700"
    }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Include\']')) + " ")]);
  })], 2), _c('tr', [_c('th', [_c('b-badge', {
    staticClass: "w-100 py-50",
    attrs: {
      "variant": "info",
      "pill": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Costs related to Covid-19\']')) + " ")])], 1)]), _c('tr', [_c('th', {
    staticClass: "text-right"
  }, [_c('b-badge', {
    staticClass: "py-50 px-75",
    attrs: {
      "variant": "warning",
      "pill": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Up to 70 years old\']')) + " ")])], 1), _c('td', {
    attrs: {
      "colspan": _vm.dataListInsurances.length
    }
  }, [_c('div', {
    staticClass: "d-flex-between fw-700"
  }, [_c('div', {
    staticClass: "flex-1 mr-50 text-center"
  }, [_c('div', {
    staticClass: "flex-1 text-center mb-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Apply package\']')) + ": "), _c('span', {
    staticClass: "text-info"
  }, [_vm._v("Cơ bản 1, cơ bản 2")])]), _vm._v(" " + _vm._s(_vm.$t('reservation[\'Subject to limits and health insurance benefits specified in the contract/Certificate of insurance\']')) + " ")]), _c('div', {
    staticClass: "flex-1 ml-50 text-center"
  }, [_c('div', {
    staticClass: "flex-1 text-center mb-50"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Apply package\']')) + ": "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Phổ thông, cao cấp, thượng hạng")])]), _vm._v(" " + _vm._s(_vm.$t('reservation[\'Maximum $50,000/person/Insured period, whichever is lower\']')) + " ")])])])]), _c('tr', [_c('th', {
    staticClass: "text-right"
  }, [_c('b-badge', {
    staticClass: "py-50 px-75",
    attrs: {
      "variant": "warning",
      "pill": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Over 70 years old\']')) + " ")])], 1), _c('td', {
    attrs: {
      "colspan": _vm.dataListInsurances.length
    }
  }, [_c('div', {
    staticClass: "d-flex-between fw-700"
  }, [_c('div', {
    staticClass: "flex-1 mr-50 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'$5,000/person/insurance period, whichever is lower\']')) + " ")]), _c('div', {
    staticClass: "flex-1 ml-50 text-center"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Maximum $5,000/person/term of insurance, whichever is lower\']')) + " ")])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }